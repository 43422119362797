.leaderboardContainer {
    display: flex;
    flex-direction: column;
}

.topControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
}

.hidde {
    opacity: 0;
}

.seedText {
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
}

.switch {
    color: #B5B5B5;
    font-size: 16px;
    font-weight: 500;
}

.active {
    color: #FFFFFF;
    font-weight: 700;
}

@media (max-width: 900px) {
    .topControls {
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
    }
}
