.rulesSection {
    background-color: var(--bgDark);
    padding-bottom: 170px;
}

.rulesTitle {

}

.rulesSubtitle {
    max-width: 50%;
}

.rulesContent {
    margin-top: 50px;
}