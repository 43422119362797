.container {
    width: 100%;
    min-height: calc(300px * 1.2);
    display: flex;
    align-items: center;
}

.testTwitch {
    width: 100%;
    min-height: 300px;
}
