.newsLetterSignupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.signUpForm {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.signUpButton {
    margin-right: -40px;
    z-index: 1;
}


.emailInput {
    flex-grow: 1;
}