.playerSection {

    margin-top: 180px;
}


.playersContainer {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns with equal width */
    gap: 3px 20px; /* Spacing between grid items */
}