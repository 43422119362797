/* TopAvatar.module.css */

.topAvatar {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rankContainer {
    display: flex;
    margin-bottom: 10px;
}

.rankIcon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    margin-top: 3px;
}

.rankText {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-family: Belfast Grotesk, serif;
    font-weight: 900;
    margin-top: 0px;
    text-transform: uppercase;
}

.avatarContainer {
    position: relative;
}

/*.avatarContainer::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    top: 0;*/
/*    left: 0;*/
/*    border-radius: 50%;*/
/*    border: 5px solid #828282;*/
/*    box-sizing: border-box;*/
/*    border-right-color: transparent;*/
/*    border-bottom-color: transparent;*/
/*}*/

.starBackground {
    background-image: url('../../../../../assets/website/layout/star.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; /* Hier kannst du den Wert anpassen */

}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.name {
    color: #B5B5B5;
}

.team, .time, .points {
    color: #B5B5B5;
    margin-bottom: 6px;
}

.time .points {
    font-width: 700;
}

.points {
    color: #10D2C4;
}

