
.timePartContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
}

.label {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.heading {
    font-family: 'Belfast Grotesk', 'Montserrat', sans-serif !important;
    margin: 0;
    font-weight: 900 !important;
    font-size: 90px !important;
}

@media (max-width: 900px) {

    .timePartContainer {
        width: unset;
    }
    .label {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
    }

    .heading {
        font-family: 'Belfast Grotesk', 'Montserrat', sans-serif !important;
        margin: 0;
        font-weight: 900 !important;
        font-size: 45px !important;
    }
}


