:root {
    --bar-height: 5px;
    --progress: 75%;
}

.container {
    position: relative;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px;
    margin-right: 15px;
    height: var(--bar-height);
    background-color: #FFFFFF;
    border-radius: 10px;
}

.progressBar {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00AE7B;
    width: calc(100%*var(--progress));
    height: 100%;
}


.pointContainer {
    z-index: 2;
    position: absolute;
    left: -7px;
    top: calc(-7px + calc(var(--bar-height) / 2));
    width: calc(100% + 14px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.point {
    width: 15px;
    height: 15px;
    background-color: #FFFFFF;
    rotate: 45deg;
}


.reached {
    background-color: #00AE7B;
}
