.avatarContainer {
    margin-top: 120px;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 70px;
}

.rowsContainer {
    margin-top: 80px;
}

