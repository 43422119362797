.countdownSection {
    min-height: 100vh;
    position: relative;
}

.countdownSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../../assets/website/home/coutndown_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.layer2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    mix-blend-mode: multiply;
    opacity: 0.7;
}

.countdownWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
}

@media (max-width: 900px) {
    .countdownSection::before {
        background-size: cover;
    }

}
