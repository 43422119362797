
.countdownContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.timeContainer {
    display: flex;
    flex-direction: row;
}

.separator {
    font-family: 'Belfast Grotesk', sans-serif !important;
    margin: 0 5px;
    font-weight: 900 !important;
    font-size: 90px !important;
}

.spacedText {
    color: #FFF;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 33px;
    letter-spacing: 9px;
}

@media (max-width: 900px) {
    .timeContainer {
        margin-top: 30px;
        border: 1px solid white;
    }
    .mobileBreak {
        display: none;
    }

    .separator {
        margin: 0 5px;
        font-weight: 900 !important;
        font-size: 45px !important;
    }
}

