.container {

}


.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 44px 32px 64px;
    max-width: 50%;
    min-height: 280px;
    margin-left: auto;
    text-align: start;
}


.monsterContainer {
    position: absolute;
    top: -15px;
    left: -32px;
    z-index: 3;
}

