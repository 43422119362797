:root {
    --row-color: #FFFFFF;
}


.mapLeaderboardRow {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px;
    background-color: var(--row-color);
}


.rank {
    font-weight: 700;
    font-size: 21px;
    flex: 0 0 5%; /* 3% der Breite */
}

.name {
    font-weight: 500;
    font-size: 21px;
    flex: 0 0 15%; /* 22% der Breite */
}

.team {
    font-weight: 400;
    font-size: 18px;
    color: #23F6CD;
    flex: 0 0 15%; /* 15% der Breite */
    white-space: nowrap;      /* Prevent text from wrapping to the next line */
    overflow: hidden;         /* Hide overflow */
    text-overflow: ellipsis;
}


.placeholder {
    flex: 0 0 35%;
}

.time {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #B5B5B5;
    flex: 0 0 15%;
}

.points {
    font-weight: 400;
    font-size: 18px;
    color: #10D2C4;
    flex: 0 0 15%;
}

@media (max-width: 1100px) {

    .mapLeaderboardRow {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
    }

    .rank {
        flex: 0 0 5%; /* 3% der Breite */
    }

    .name {
        flex: 0 0 30%; /* 15% der Breite */
    }

    .team {
        flex: 0 0 55%; /* 15% der Breite */
    }

    .time {
        flex: 0 0 30%; /* 15% der Breite */
    }

    .points {
        flex: 0 0 40%; /* 15% der Breite */
    }

    .placeholder {
        display: none;
    }
}
