.container {
    min-height: 45vh;
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    text-align: center;
}

.gradientBackground {
    background: var(--gradientSecondary);
}


.comingSoon {
    margin-top: 120px;
    color: #23F6CD;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 18px;
    text-transform: uppercase;
}