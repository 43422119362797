.container {
    display: flex;
    align-items: center;
}

.icon {
    width: 24px; /* Passe die Breite des Icons an */
    height: 24px; /* Passe die Höhe des Icons an */
    margin-right: 16px; /* Füge einen geeigneten Abstand zwischen Icon und Text hinzu */
}

.text {
    color: #FFF;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px; /* 206.25% */
    text-decoration-line: underline;
}
