.slider {
    position: relative;
    width: 100%;
    min-height: 400px;
    overflow: hidden;
}

.slidesContainer {
    display: flex;
    height: 100%;
    transition: transform 0.5s ease-in-out;
}

.slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}

.slide.active {
    transform: translateX(0);
}


.sliderIndicators {
    position: absolute;
    top: 10%;
    left: 50%;
    border: none;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
}

.sliderIndicator {
    width: 75px;
    height: 3px;
    background-color: white;
    opacity: 0.8;
    margin: 0 5px;
    transition: opacity 0.3s ease-in-out,  transform 0.3s ease-in-out;
}

.sliderIndicator:hover {
    transform: scale(1.1);
    cursor: pointer;

}

.sliderIndicator.active {
    background-color: var(--primary-light);
}



/* Controls */

.sliderControls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
}
.sliderControl {
    height: 27px;
    width: 27px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-top: 3px solid var(--bg);
    border-right: 3px solid var(--bg);
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.sliderControl:hover {
    transform: scale(1.3);
    cursor: pointer;
}

.sliderPrev {
    margin-left: 30px;
    transform: rotate(-135deg);
}

.sliderNext {
    margin-right: 30px;
    transform: rotate(45deg);
}

.sliderPrev:hover,
.sliderPrev:focus {
    transform: rotate(-135deg) scale(1.3);
}

.sliderNext:hover,
.sliderNext:focus {
    transform: rotate(45deg) scale(1.3);
}
