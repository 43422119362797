
.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 52px 32px 64px;
    max-width: 40%;
    min-height: 280px;
    margin-right: auto;
}


.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 32px;
    z-index: 20;
}

.buttonContainer button:not(:last-child) {
    margin-right: 32px;
}

.playerContainer {
    position: absolute;
    top: -32px;
    right: 32px;
    background-color: purple;
    z-index: 3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

