.container {

}

.textBlock {
     margin-bottom: 24px;
 }


.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 32px;
    z-index: 20;
}

.buttonContainer button:not(:last-child) {
    margin-right: 32px;
}