
.playerCardContainer {
    position: relative;
    width: 600px;
    height: 300px;
}

.cardBackContent,
.cardFrontContent {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;

    background-color: var(--bg);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}



.avatar {
    width: 220px; /* Beispielgröße, anpassen wie benötigt */
    height: 220px;
    border-radius: 50%; /* Macht den Avatar rund */
    object-fit: cover; /* Behält das Seitenverhältnis des Bildes bei */
    z-index: 3; /* Stellt sicher, dass der Avatar über dem Gradienten liegt */
}

.cardBackContent {
    z-index: 0;
}

.active {
    transform: rotateY(0);
}

.playerTopControls {
}

.playerSocialsContainer {
    top: 7%;
    left: 5%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.playerSocialsContainer img {
    margin-right: 12px;
}

.playerSocialsContainer img:hover {
    cursor: pointer;
}


.flipArrow {
    position: absolute;
    top: 7%;
    right: 5%;
}

.flipArrow:hover {
    cursor: pointer;
}

.playerInfoContainer {
    position: absolute;
    bottom: 7%;
    left: 5%;
    display: flex;
    flex-direction: column;
}

.playerTag {
    font-size: 21px;
    font-weight: 700;
}

.teamTag {
    font-size: 16px;
    font-weight: 700;
    color: #D9D9D9;
}



